<template>
  <div>
    <b-row align-v="center" :class="{ 'mt-5 p-2' : $mq === 'sm' || $mq === 'xs' || $mq === 'md'}" align="center">
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <title-page title="SOBRE" class="titulo"/>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="4" xl="4">
        <b-img src="img/HEBER_MASCOTE.png" fluid v-if="!imgWebp"/>
        <b-img src="img/HEBER_MASCOTE.webp" fluid v-if="imgWebp"/>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="8" xl="8">
        <div class="texto">
          Héber Lima, advogado especialista em Direito Eleitoral pelo Instituto Brasiliense de Direito Público (IDP/DF) e em Gestão, Governança e Setor Público pela Pontifícia Universidade Católica do Rio Grande do Sul (PUC/RS). <br><br>
          Atua em coordenação de campanhas eleitorais, municipais e estaduais, há mais de 20 anos, como consultor jurídico, palestrante e na capacitação de advogados e membros de equipes que atuam nos pleitos.  <br><br>
          Foi assessor do Ministério Público do Estado do Maranhão por 08 anos, com ênfase em Direito Civil e Eleitoral.  <br><br>
          Exerceu o cargo de assessor jurídico na Secretaria de Desenvolvimento Social do Estado Maranhão.  <br><br>
          Possui experiência profissional na administração pública, visando sempre a busca de soluções ágeis, eficientes e adequadas. Produz conteúdo especializado em Direito Eleitoral e eleições em suas redes sociais. <br><br>
          <b-row>
            <b-col cols="12" sm="12" md="6" lg="4" xl="4" class="mt-2">
              <a href="files/CV.pdf" target="_blank">DOWNLOAD CV</a>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TitlePage from '../components/TitlePage.vue'
export default {
  name: 'sobre',
  components:{
      TitlePage
  },
  computed:{
    imgWebp(){
      return this.canUseWebP()
    }
  },
  methods: {
    canUseWebP() {
      var elem = document.createElement('canvas');
      if (!!(elem.getContext && elem.getContext('2d'))) return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
      return false;
    },
  },
  created(){
    window.scrollTo({top: 0, behavior: 'smooth'});
    document.body.style.backgroundColor = "#0D245E"
    document.body.style.backgroundImage = this.$mq !== 'xs' 
                                          && this.$mq !== 'sm' 
                                          && this.$mq !== 'md' ? 
                                            !this.imgWebp ? "url('/img/BG_SOBRE.jpg')" : "url('/img/BG_SOBRE.webp')" : 
                                            !this.imgWebp ? "url('/img/sm/BG_SOBRE.jpg')" : "url('/img/sm/BG_SOBRE.webp')"
  }
}
</script>
<style lang="scss" scoped>
.texto{
  padding: 10px;
  text-align: justify !important;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 18px;
}
.titulo{
  margin-bottom: 60px !important;
}
a{
  display: block !important;
  background-color: #0a7bac;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 18px;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  text-decoration: none !important;
  width: 100%;
  text-align: center !important;
  &:hover{
    background-color: #0098DA;
  }
}
</style>